
.modal
    position: fixed
    z-index: 4
    left: 0
    top: 0
    width: 100%
    height: 100%
    overflow: auto
    background-color: rgba(0,0,0,0.6)
    display: flex
    align-items: center
  
    .container
      -webkit-transition: opacity 1s ease
      -moz-transition: opacity 1s ease
      -o-transition: opacity 1s ease
      transition: opacity 1s ease
      background: #fff
      border-radius: 5px
      margin: 70px auto
      overflow: auto
      position: relative
      height: auto
      width: 600px

      .exit 
        position: absolute
        margin: 1em
        cursor: pointer

@media (max-width: 600px)
  .modal .container
    margin: auto
    max-width: 600px