.signUpForm
    padding: 3em

@media (max-width: 600px)
  .signUpForm
    margin-top: 0

@media (max-width: 400px)
    h3
      font-size: 1em
