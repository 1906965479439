.getStarted
    align-items: center
    display: flex
    flex-direction: column
    justify-content: center
    text-align: center
    padding: 2em
    position: relative
    height: 240px

    .background
        position: absolute
        background-position: right -200px bottom 0px, left -200px bottom 0px
        background-repeat: no-repeat, no-repeat
        background-size: contain
        opacity: 0.5
        height: 100%
        width: 100%

.hero
    a,
    button
        width: 200px
        box-sizing: border-box
        text-align: center

    h1
        font-size: 3em
        max-width: 400px
        color: #fff

.location
    padding: 3em

    .card
        padding: 1em
        height: 540px
        margin: 0 auto
        max-width: 900px

        .columnLeft
            align-items: flex-end
            background-size: cover
            background-repeat: no-repeat
            display: flex
        
        .columnRight
            align-items: center
            background-size: cover
            background-repeat: no-repeat
            display: flex
            flex-direction: column
            justify-content: center
            text-align: center

            .search
                display: flex
                align-items: center

            .button
                align-items: center
                border: none
                border-radius: 50px
                display: inline-flex
                font-size: 14px
                height: 50px
                justify-content: center
                padding-left: 1.5em
                padding-right: 1.5em
            
            .input
                border: none
                border-radius: 50px
                height: 50px
                padding-left: 1.5em
                padding-right: 1.5em

.review
    background-position: left 200px bottom 0px
    background-repeat: no-repeat
    background-size: 45%
    margin-bottom: 3em

    .reviewContainer
        align-items: flex-end
        display: flex
        flex-direction: column

        div:last-child
            margin: 3em 0

        img
            max-width: 400px
            width: 100%

            &:last-child
                margin-left: 2em

.tagline
    text-align: center
    padding: 2em

.app
    padding-bottom: 2em

    .onTheGo
        font-size: 56px

    .phone
        max-height: 500px
        width: auto

.mobileButtonContainer
    display: none

@media (max-width: 1100px)
    .getStarted .background
        background-image: none !important
    
    .review
        background-image: none !important
        margin-bottom: 0

        .reviewContainer
            align-items: center

            // div
            //     display: flex
            //     flex-direction: column

            //     &:last-child
            //         margin: 0

            // img
            //     &:last-child
            //         margin-left: 0
                
            //     margin-bottom: 3em

@media (max-width: 900px)
    .review .reviewContainer
        div
            display: flex
            flex-direction: column

            &:last-child
                margin: 0

        img
            &:last-child
                margin-left: 0
            
            margin-bottom: 3em

    .location
        h3
            font-size: 1.2em

        .card
            height: 300px

        .columnRight .search
            flex-direction: column

@media (max-width: 600px)
    .tagline
        h3, h6
            font-size: 1em !important
    .hero
        align-items: center
        display: flex
        flex-direction: column
        text-align: center

        h1
            font-size: 2em

        .buttonContainer
            display: none
    
    .mobileButtonContainer
        display: flex
        flex-direction: column
        align-items: center
        padding: 1em

        a,
        button
            border-radius: 50px
            margin: .5em
            width: 200px
            box-sizing: border-box
            text-align: center

    .location
        padding: 1em

        .card
            height: auto

            .columnLeft
                background: #4B368E
                background-image: none !important

                h5
                    margin: 0
            
            .columnLeft,
            .columnRight
                padding: 2em
    
    .review .reviewContainer img
        max-width: 300px
    
    .app
        .onTheGo
            font-size: 40px

        .phone
            max-height: 300px
