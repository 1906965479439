.column
    flex-grow: 1
    padding-left: 1em
    padding-right: 1em
    height: 100%

@media (max-width: 600px)
    .column
        padding-bottom: .5em
        padding-top: .5em
        width: 90% !important