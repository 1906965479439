@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600&display=swap')

input[type=text],
textarea
    font-size: 16px

html, body, main
    color: #5D5C70
    z-index: 1

html, main
    height: 100%

body
    min-height: 100%

main
    display: flex
    flex-direction: column

body
    margin: 0
    font-family: "Poppins"
    font-weight: 200

footer
    flex-shrink: 0

input:focus
    outline: 0
    box-shadow: 0 0 1px 1px #4B368E

::placeholder
    color: #4B368E
    opacity: 1

h1, h2, h3, h4, h5, h6, p
    margin: 0 0 .5em 0

h1, h2, h3, h4, h5, h6
    color: #5B42AA

h4, h5
    font-weight: 500

h6
    font-weight: 400

a
    text-decoration: none

h6, p
    font-size: 1em

.columnImage
    display: block
    width: 80%

img.left
    @extend .columnImage
    margin-right: auto

img.right
    @extend .columnImage
    margin-left: auto

@mixin psuedo($width, $a, $opacity, $blur, $left)
    content: ''
    display: block
    position: absolute
    background: rgba(255, 255, 255, $a)
    width: $width
    height: 100%
    left: $left
    top: 0
    opacity: $opacity
    filter: blur($blur)
    transform: translateX(-100px) skewX(-15deg)

@mixin hoverPsuedo($opacity)
    transform: translateX(300px) skewX(-15deg)
    opacity: $opacity
    transition: .7s

.buttonLink,
button
    background: #5B42AA
    border: none
    color: #FAF8FF
    border-radius: 5px
    cursor: pointer
    display: block
    font-size: 1em
    font-weight: 600
    padding: 1em 2em
    transition: .6s
    overflow: hidden
    position: relative

    &:focus
        outline: 0

    &:before
        @include psuedo($width: 60px, $a: 0.5, $opacity: 0.5, $blur: 30px, $left: 0)

    &:after
        @include psuedo($width: 30px, $a: 0.2, $opacity: 0, $blur: 5px, $left: 30px)

    &:hover
        background: #4B368E
        color: #FAF8FF

        &:before
            @include hoverPsuedo($opacity: 0.6)

        &:after
            @include hoverPsuedo($opacity: 1)

    &.invert
        background: #FAF8FF
        color: #5B42AA

        &:hover
            background: #EAE2FE
            color: #5B42AA

@media (min-width: 600px)
    h1
        font-size: 2.667em

    h2
        font-size: 2.333em

    h3
        font-size: 2em

    h4
        font-size: 1.667em

    h5
        font-size: 1.333em

@media (max-width: 600px)
    h1
        font-size: 1.625em

    h2
        font-size: 1.5em

    h3
        font-size: 1.375em

    h4
        font-size: 1.25em

    h5
        font-size: 1.125em

    .columnImage
        width: 100%
