.faq
    color: #fff
    padding: 3em 1em

    .question
        padding: 1em
        margin: 1em
    
        .container
            align-items: center
            display: flex
            justify-content: space-between

@media (max-width: 600px)
    .faq .question
        margin: 0

        