.headerPadding
    padding-bottom: 3em
    padding-top: 3em

.grid
    display: flex
    flex-wrap: wrap

.gridItem
    width: calc(33% - 2em)
    padding: 2em 1em
    text-align: center

.gridItem
    img
        height: 80px
    
    p
        font-size: 14px

@media (max-width: 600px)
    .gridItem
        width: calc(50% - 2em)
        padding: 1em
