.row
    align-items: flex-start
    display: flex
    flex-wrap: wrap
    margin-left: -1em
    margin-right: -1em
    height: 100%

@media (max-width: 600px)
    .reverse
        flex-direction: column-reverse