.link
    align-items: center
    color: #F1EDFA
    display: flex
    font-weight: 300
    justify-content: center
    padding: 0 1em
    position: relative
    text-align: center
    transition: background-color .2s ease-in

    &:hover
        background-color:#4B368E

.phone
    align-items: center
    border-width: 1px
    border-style: solid
    border-radius: 3px
    display: flex
    margin: 0 1em
    padding: .5em 1em

.logo
    width: 200px

@media (max-width: 1300px)
    .logo
        width: 150px

@media (max-width: 1100px)
    .phone,
    .link
        font-size: 14px
    
    .logo
        width: 125px

@media (max-width: 768px)  
    .mobile 
        display: flex
        align-items: center
        justify-content: space-between
        padding: 1em

    .mobileMenu 
        height: 40px

    .navLinks 
        border-top: 1px solid black
        padding: .5em 0
        position: fixed
        background-color: white
        height: 100%
        width: 100%
        overflow: auto
        -webkit-transition: 200ms ease-in
        -moz-transition: 200ms ease-in
        -o-transition: 200ms ease-in
        transition: 200ms ease-in

    .navLink 
        padding: 0.5em 0

    .phone
        font-size: 12px
        padding: .5em .75em
    
    .logo
        width: 100px
