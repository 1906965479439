.banner
  padding: 1.5em
  
.bannerContainer
  display: flex
  justify-content: space-between
  align-items: center

.banner, .button
  background-color: #EF767B

.button
  border-color: #FFF
  border-width: 2px
  border-style: solid
  border-radius: 3px
  color: #FFF
  display: block
  font-weight: 600
  margin: 0 1em
  padding: .5em 1em

.message 
  display: flex
  flex-direction: column
  flex-grow: 1
  text-align: center

  h4, p
    color: #FFF
    margin: 0

@media (max-width: 900px)
  .banner
    padding: 1em

  .bannerContainer
    flex-direction: column

  .button
    margin-top: .5em
