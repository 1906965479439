footer
    font-size: 12px

    .footerColumns
        display: flex
        padding: 2em 0

    h4, a
        color: #FAF8FF
    
    a.link
        display: flex
        align-items: center

    a.icon
        font-size: 3em

    svg g
        transition: fill .2s ease-in-out
        
    a
        transition: color .35s ease-in
        font-size: 1.25em
        margin-bottom: 1em
        cursor: pointer

        &:hover
            color: #F3B337

            svg g
                fill: #F3B337

    
    section
        text-align: left


@media (min-width: 600px)
    .rightColumn
        margin-left: 5em

    .follow
        margin-left: 16px
    
    a.icon
        margin: 16px


@media (max-width: 600px)
    footer
        .footerColumns
            flex-direction: column
        
        a.icon
            margin-right: 32px

// footer
//     font-size: 12px
//     padding: 1em

//     .footerColumn
//         margin: 0 auto
//         max-width: 250px

//         h4
//             color: #FAF8FF

//     .link
//         display: block
//         font-size: 1.333em

//     .iconRow
//         margin-left: -10px
//         margin-right: -10px

//     a.icon
//         font-size: 3em
//         margin: 10px

//     a
//         color: #FAF8FF
//         transition: .6s

//         &:hover
//             color: #F3B337

//     p
//         text-align: center